import { useMemo, useState, ReactNode } from 'react';
import { ConfigContext } from './ConfigContext';
import { Theme, config } from '../../../config';
import theme from '../../../stores/retailer-theme-link';

function ConfigProvider({ children }: { children: ReactNode }) {
  const [configOverride, setConfigOverride] = useState<Theme | null>(null);

  const contextValue = useMemo(
    () => ({
      retailerName: config.retailerName,
      apiBaseUrl: config.apiBaseUrl,
      configOverride: configOverride ?? theme,
      setConfigOverride,
    }),
    [configOverride],
  );

  return (
    <ConfigContext.Provider value={contextValue}>
      {children}
    </ConfigContext.Provider>
  );
}

export default ConfigProvider;
