import { IdsProvider } from '@instacart/ids-core';

import { useConfig } from '../common/hooks/config/useConfig';

function IdsThemeOverridesProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { configOverride } = useConfig();

  return (
    <IdsProvider themeOverrides={configOverride.styles}>{children}</IdsProvider>
  );
}
export default IdsThemeOverridesProvider;
