import { css } from '@emotion/react';
import { useTheme } from '@instacart/ids-core';

type Props = {
  viewAllLink: string;
  totalCount: number;
};

const useStyles = () => {
  const theme = useTheme();

  return {
    viewAllBtn: css`
      color: ${theme.colors.brandPrimaryRegular};
      text-align: right;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      margin-right: 10px;
      cursor: pointer;
    `,
  };
};
export default function ViewAllCollection({ viewAllLink, totalCount }: Props) {
  const styles = useStyles();

  return (
    <a css={styles.viewAllBtn} href={viewAllLink}>
      View All ({totalCount})
    </a>
  );
}
