import { AuthWidget, AvatarContainer, ProfileWidget } from 'sfx-app';
import { css } from '@emotion/react';
import { useAuth } from '../../common/hooks/auth/useAuth';
import { useConfig } from '../../common/hooks/config/useConfig';
import { useIsMobile } from '../../common/hooks/useIsMobile';

const useStyles = () => ({
  anchor: css`
    text-decoration: none;
  `,
});
export default function AuthWidgetWrapper() {
  const { authState, user } = useAuth();
  const { configOverride, retailerName } = useConfig();
  const baseUrl = import.meta.env.VITE_SHOP_HOST_URL;
  const isMobile = useIsMobile();
  const styles = useStyles();

  const handleAuth = () => {
    // The Garden environment does not have a external sso enabled
    // TODO: review support/scope for non external sso retailers
    if (retailerName === 'the-garden') {
      window.open(`${baseUrl}/login`, '_self');
    } else {
      const url = `${baseUrl}/rest/sso/auth/${retailerName}/init?return_to=${encodeURIComponent(
        window.location.href,
      )}`;
      window.open(url, '_self');
    }
  };

  if (!authState) {
    return (
      <AuthWidget
        onPress={handleAuth}
        configOverrides={configOverride.authWidget}
      />
    );
  }

  if (isMobile && configOverride.profileMenu.clickUrlOnMobile) {
    return (
      <a
        css={styles.anchor}
        href={`${baseUrl}/${configOverride.profileMenu.clickUrlOnMobile}`}
      >
        <AvatarContainer
          configOverrides={configOverride.profileMenu}
          user={user}
        />
      </a>
    );
  }

  return (
    <ProfileWidget
      configOverrides={configOverride.profileMenu}
      baseUrl={baseUrl}
      user={user}
    />
  );
}
