import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  useCachedProducts,
  useCart,
  useStyles,
  useTileWidth,
} from './custom-hooks';
import ViewAllCollection from './ViewAllCollection';
import { useCallback, useRef, useState } from 'react';
import ProductCard from './ProductCard';
import { spacing } from '@instacart/ids-core';
import { ShopContext } from '../../common/hooks/store/useStoreInitializer';

type Props = {
  productIds: string[];
  viewAllLink: string | null;
  shopId?: string;
  shopContext?: ShopContext;
};

export default function ScrollableList({
  viewAllLink,
  productIds,
  shopId,
  shopContext,
}: Props) {
  const styles = useStyles();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { itemWidth, itemsPerPage } = useTileWidth({
    ref: containerRef,
    productIds,
  });
  const { getProductInfo, products } = useCachedProducts(shopId);
  const { cartCollectionItems, putCartItems, setCartItemsCount } = useCart();

  // Create a component-lifetime ResizeObserver to handle setting list height
  const [maxProductTileHeight, setMaxProductTileHeight] = useState(0);
  const [heightResizeObserver] = useState(() => {
    return new ResizeObserver((entries) => {
      const newMaxHeight = Math.max(
        ...entries.map((e) => e.borderBoxSize[0].blockSize),
      );
      setMaxProductTileHeight((height) =>
        newMaxHeight > height ? newMaxHeight : height,
      );
    });
  });

  // TODO: future improvement would be to make use of react-window item data to
  // pass all these memoized dependencies, but this works as well for preventing
  // mounting/unmounting of the ProductCard component instances
  const ItemRenderer = useCallback(
    ({ index, style }: { index: number; style: object }) => (
      <div style={style}>
        <ProductCard
          heightResizeObserver={heightResizeObserver}
          cardWidth={itemWidth - spacing.s16}
          item={products[productIds[index]]}
          cartCollectionItems={cartCollectionItems}
          setCartItemsCount={setCartItemsCount}
          putCartItems={putCartItems}
          shopContext={shopContext}
        />
      </div>
    ),
    [
      heightResizeObserver,
      cartCollectionItems,
      itemWidth,
      productIds,
      products,
      putCartItems,
      setCartItemsCount,
      shopContext,
    ],
  );

  const totalCount = productIds.length;

  return (
    <div ref={containerRef} css={styles.wrapper}>
      {viewAllLink && totalCount > 0 ? (
        <div css={styles.buttonBar}>
          <ViewAllCollection
            viewAllLink={viewAllLink}
            totalCount={totalCount}
          />
        </div>
      ) : null}
      <AutoSizer disableHeight>
        {({ width }) => (
          <InfiniteLoader
            isItemLoaded={(index) => !!products[productIds[index]]}
            itemCount={totalCount}
            loadMoreItems={(startIndex, stopIndex) =>
              getProductInfo(productIds.slice(startIndex, stopIndex + 1))
            }
            threshold={itemsPerPage}
          >
            {({ onItemsRendered, ref: listRef }) => (
              <FixedSizeList
                ref={listRef}
                css={styles.noScrollbar}
                onItemsRendered={onItemsRendered}
                itemKey={(index) => productIds[index]}
                height={maxProductTileHeight}
                itemCount={totalCount}
                itemSize={itemWidth}
                width={width}
                layout="horizontal"
              >
                {ItemRenderer}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </div>
  );
}
