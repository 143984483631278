import { lazy, Suspense, useEffect, useState } from 'react';
import r2wcCore, { R2WCRenderer } from '@r2wc/core';
import { ComponentLoader, domElementComponentMap } from '../dom-element-map';
import { createPortal } from 'react-dom';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

function initializeWebComponents<Context>(
  r2wcRenderer: R2WCRenderer<object, Context>,
) {
  for (const [tagName, componentEntry] of Object.entries(
    domElementComponentMap,
  )) {
    const WebComponent = r2wcCore(
      lazy(componentEntry.componentLoader as ComponentLoader<object>),
      { shadow: 'closed', props: componentEntry.props },
      r2wcRenderer,
    );
    if (customElements.get(tagName)) {
      // console.warn('Custom element already registered for ', tagName);
      return;
    } else {
      customElements.define(tagName, WebComponent);
    }
  }
}

type FoundElement = {
  domElement: Element;
  jsxElement: JSX.Element;
  uniqueId: number;
};

let nextUniqueId = 1;
function newUniqueId() {
  return nextUniqueId++;
}

export default function WidgetPortalGun() {
  const [activeElements, setActiveElements] = useState<FoundElement[]>([]);

  useEffect(() => {
    type Context = { uniqueId: number };
    const mount = (
      container: HTMLElement,
      ReactComponent: React.ComponentType<object>,
      props: object,
    ): Context => {
      const uniqueId = newUniqueId();
      setActiveElements((prev) => {
        const emotionCache = createCache({
          key: 'insta-widgets',
          container,
          insertionPoint: container,
        });
        const newElement = {
          domElement: container,
          jsxElement: (
            <Suspense fallback={<span>Loading...</span>}>
              <CacheProvider value={emotionCache}>
                <ReactComponent {...props} />
              </CacheProvider>
            </Suspense>
          ),
          uniqueId,
        };
        return [...prev, newElement];
      });
      return { uniqueId };
    };

    const update = (context: Context, props: object): void => {
      setActiveElements((elements) => {
        const existingIndex = elements.findIndex(
          (element) => element.uniqueId === context.uniqueId,
        );
        if (existingIndex === -1) {
          console.warn(
            'Dom element update, but could not find element in React to update',
          );
          return elements;
        }
        const existingElement = elements[existingIndex];
        return [
          ...elements.slice(0, existingIndex),
          {
            ...existingElement,
            props,
          },
          ...elements.slice(existingIndex + 1),
        ];
      });
    };
    const unmount = (context: Context): void => {
      setActiveElements((elements) =>
        elements.filter((element) => element.uniqueId !== context.uniqueId),
      );
    };

    initializeWebComponents({
      mount,
      update,
      unmount,
    });
  }, []);

  return (
    <>
      {activeElements.map(({ jsxElement, domElement, uniqueId }) => {
        if (domElement.isConnected) {
          return createPortal(jsxElement, domElement, uniqueId.toString());
        }
      })}
    </>
  );
}
