import { useState, ReactNode, useEffect } from 'react';
import { AuthContext, UserData } from './AuthContext';
import { getUser } from '../../services/user.service';

function AuthProvider({ children }: { children: ReactNode }) {
  const [authState, setAuthState] = useState(false);
  const [user, setUser] = useState<UserData>({
    user_id: '',
    first_name: '',
    last_name: '',
    guest: true,
  });

  useEffect(() => {
    async function fetchUser() {
      try {
        const userData = await getUser();
        setUser(userData.data);
        // Set auth state based on if the user is a guest or not
        if (userData.data.guest) {
          setAuthState(false);
        } else {
          setAuthState(true);
        }
      } catch (error) {
        setAuthState(false);
        if (error instanceof Error) {
          console.error(`Failed to verify user: ${error.message}`);
        } else {
          console.error(`Failed to verify user: ${String(error)}`);
        }
      }
    }
    void fetchUser();
  }, [setAuthState, setUser]);

  return (
    <AuthContext.Provider
      value={{
        authState,
        setAuthState,
        user,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
