import axios from 'axios';
import { config } from '../../config';
import {
  CartsPersonalData,
  CartsPersonalType,
} from '../hooks/carts/useCartsPersonal';

const { apiBaseUrl } = config;

export type CartItem = {
  product_id: string;
  quantity: number;
  quantity_type?: string;
};

export type CartItems = {
  items: CartItem[];
};

export const getCartsPersonal = async () => {
  return await axios.get<CartsPersonalData>(
    `${apiBaseUrl}/idp/v1/carts/personal`,
    {
      withCredentials: true,
    },
  );
};

export const updateCartItems = async (
  cartId: string,
  shopId: string,
  cartItem: CartItem,
) => {
  return await axios.put<CartsPersonalType>(
    `${apiBaseUrl}/idp/v1/carts/${cartId}/items`,
    { shop_id: shopId, cart_items: cartItem },
    {
      withCredentials: true,
    },
  );
};

export const getCartItems = async (cartId: string) => {
  return await axios.get<CartItems>(
    `${apiBaseUrl}/idp/v1/carts/${cartId}/items`,
    {
      withCredentials: true,
    },
  );
};
