import { useState, useEffect } from 'react';
import { getCartsPersonal } from '../../services/cart.service';
import { AxiosError } from 'axios';

export type CartsPersonalType = {
  id: string;
  item_count: number;
};

export type CartsPersonalData = {
  carts: CartsPersonalType[];
};

type CartsPersonalState = {
  loading: boolean;
  data?: CartsPersonalData | undefined;
  error?: AxiosError;
};

const useCartsPersonal = (): CartsPersonalState => {
  const [cartsPersonal, setCartsPersonal] = useState<CartsPersonalState>({
    loading: false,
  });

  useEffect(() => {
    const fetchCartsPersonal = async () => {
      try {
        setCartsPersonal((cartsPersonal) => ({
          ...cartsPersonal,
          loading: true,
        }));
        const cartsData = await getCartsPersonal();
        setCartsPersonal({
          data: cartsData.data,
          loading: false,
          error: undefined,
        });
      } catch (error) {
        setCartsPersonal({
          data: undefined,
          error: error as AxiosError,
          loading: false,
        });
      }
    };

    void fetchCartsPersonal();
  }, []);

  return cartsPersonal;
};

export default useCartsPersonal;
