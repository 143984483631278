import { useState, useRef, KeyboardEvent, useEffect } from 'react';
import { css } from '@emotion/react';
import { isMobile } from 'react-device-detect';
import {
  CloseIcon,
  useTheme,
  ActionSheetDropdownItem,
  spacing,
  ActionSheetItemIcon,
  Color,
  responsive,
} from '@instacart/ids-core';
import { ProfileMenuConfig } from 'widget-wrappers/config';
import { UserData } from 'widget-wrappers/common/hooks/auth/AuthContext';
import { AvatarContainer } from './AvatarContainer';

const useStyles = (overrides: ProfileMenuConfig['styles']) => {
  const theme = useTheme();
  return {
    profileContainer: css`
      position: relative;
      width: fit-content;
    `,
    actionSheet: css`
      align-items: flex-start;
      flex-direction: column;
      border-width: 2px;
      display: flex;
      width: 222px;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
      padding: ${spacing.s8}px;
      background-color: ${theme.colors.systemGrayscale00};
      position: relative;
      border-radius: ${overrides?.cornerRadius || '0px'};
      box-sizing: border-box;
      ${responsive.down('c')} {
        border-radius: ${spacing.s12}px;
        border-radius: 0px;
        width: 100%;
        height: 100%;
      }
    `,
    actionSheetItem: css`
      background-color: ${theme.colors.systemGrayscale00};
      border-radius: 8px;
      font-family: inherit;
      &:hover {
        background-color: ${theme.colors.brandSecondaryLight};
      }
      &:focus {
        outline: none;
      }
      &:focus-visible::after {
        border: 2px solid ${theme.colors.systemGrayscale80};
        border-radius: ${spacing.s8}px;
        content: '';
        display: block;
        position: absolute;
        top: 0px;
        left: 7px;
        right: 7px;
        bottom: 0px;
      }
      ${responsive.down('c')} {
        padding-top: 4px;
        padding-bottom: 4px;
        display: flex;
        justify-content: ${overrides?.mobilePosition || 'left'};
      }
    `,
    contentContainer: css`
      display: flex;
      align-items: center;
      & > svg {
        min-width: 24px;
        height: 24px;
      }
    `,
    label: css`
      font-size: ${overrides?.profileItemFontSize || '14px'};
      font-weight: 500;
      text-align: left;
      color: ${theme.colors.brandSecondaryDark};
      white-space: normal;
      ${responsive.down('c')} {
        line-height: 40px;
      }
    `,
    dropdownContainer: css`
      z-index: 9999;
      position: absolute;
      ${responsive.down('c')} {
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        right: 0;
      }
      ${overrides?.dropDownMenuStyles};
    `,
    closeIconContainer: css`
      display: none;
      ${responsive.down('c')} {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 2px;
      }
    `,
    closeIcon: css`
      width: 24px;
      height: 24px;
    `,
  };
};

export type ProfileMenuProps = {
  configOverrides: ProfileMenuConfig;
  baseUrl: string;
  user: UserData | null;
};

export const ProfileWidget = ({
  configOverrides,
  baseUrl,
  user,
}: ProfileMenuProps) => {
  const styles = useStyles(configOverrides.styles);
  const [menuShown, setMenuShown] = useState(false);
  const dropDownRef = useRef<HTMLDivElement>(null);

  const navigateTo = (link: string, type?: string) => {
    if (type) {
      if (type === 'logout') {
        const logoutUrl = `${baseUrl}${link}?return_to=${encodeURIComponent(
          window.location.href,
        )}`;
        window.open(logoutUrl, '_self');
      }
    } else {
      window.open(`${baseUrl + link}`, '_self');
    }
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    switch (e.code) {
      case 'Enter':
        setMenuShown(true);
        return;
      case 'Escape':
        setMenuShown(false);
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    if (isMobile) {
      if (menuShown) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }
  }, [menuShown]);

  return (
    <div
      data-testid="profile-widget-container"
      onMouseEnter={() => {
        dropDownRef.current?.blur();
        setMenuShown(true);
      }}
      onMouseLeave={() => {
        dropDownRef.current?.focus();
        setMenuShown(false);
      }}
      css={styles.profileContainer}
      onKeyDown={handleKeyPress}
    >
      <AvatarContainer
        configOverrides={configOverrides}
        ref={dropDownRef}
        user={user}
      />
      {menuShown && (
        <div data-testid="dropdown-menu" css={styles.dropdownContainer}>
          <div
            id="dropdown"
            css={styles.actionSheet}
            role="menu"
            aria-labelledby="profileIcon"
          >
            <span css={styles.closeIconContainer}>
              <CloseIcon
                css={styles.closeIcon}
                onClick={() => setMenuShown(false)}
              />
            </span>
            {configOverrides.profileOptions?.length > 0 &&
              configOverrides.profileOptions.map(
                ({ icon, color, label, link, type }) => (
                  <ActionSheetDropdownItem
                    css={styles.actionSheetItem}
                    key={label}
                    title={label}
                    onClick={() => {
                      navigateTo(link, type);
                    }}
                    role="menuitem"
                  >
                    <div css={styles.contentContainer}>
                      {configOverrides.showProfileIcon && (
                        <ActionSheetItemIcon
                          icon={icon}
                          color={
                            configOverrides.profileIconColor || (color as Color)
                          }
                        />
                      )}
                      <span css={styles.label}>{label}</span>
                    </div>
                  </ActionSheetDropdownItem>
                ),
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileWidget;
