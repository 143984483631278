import axios from 'axios';
import { config } from '../../config';

const { apiBaseUrl } = config;

export const init = async () => {
  return await axios.post(
    `${apiBaseUrl}/idp/v1/init`,
    {},
    { withCredentials: true },
  );
};
