import { atom } from 'recoil';

export const cartItemsCountAtom = atom<number>({
  key: 'cartItemsCount',
  default: 0,
});

export const cartIdAtom = atom<string>({
  key: 'cartId',
  default: '',
});
