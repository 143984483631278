import { useMediaQuery } from 'usehooks-ts';
import { useConfig } from './config/useConfig';

export const useIsMobile = () => {
  const {
    configOverride: { mobileBreakpoint },
  } = useConfig();

  return useMediaQuery(`(max-width: ${mobileBreakpoint ?? 768}px)`);
};
