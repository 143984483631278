import { css } from '@emotion/react';
import { CartButtonBase } from '@instacart/ids-customers';
import { Theme, useTheme } from '@instacart/ids-core';
import { CartWidgetConfig } from 'widget-wrappers/config';

export type CartButtonProps = {
  cartItemsCount: number;
  openCart: () => void;
  configOverrides?: CartWidgetConfig;
};

const useStyles = (theme: Theme, overrides: CartWidgetConfig['styles']) => ({
  button: css`
    height: 48px;
    width: 85px;
    border-radius: ${overrides?.borderRadius || `${theme.radius.r24}px`};
    border-width: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${overrides?.backgroundColor ||
    theme.colors.brandSecondaryLight};

    &:focus {
      outline: none;
      border: ${overrides?.borderWidth || '2px'} solid
        ${overrides?.borderColor || theme.colors.systemGrayscale80};
    }
    & > svg {
      width: 24px;
      height: 24px;
      fill: ${overrides?.iconColor || theme.colors.brandSecondaryDark};
    }
  `,
  text: css`
    color: ${overrides?.textColor || theme.colors.brandSecondaryDark};
    font-weight: 700;
    font-size: ${overrides?.fontSize || '15px'};
    margin-left: 7px;
  `,
});

export default function CartWidget({
  cartItemsCount,
  openCart,
  configOverrides,
}: CartButtonProps) {
  const theme = useTheme();
  const styles = useStyles(theme, configOverrides?.styles);

  return (
    <CartButtonBase
      itemCount={cartItemsCount}
      styles={styles}
      onClick={openCart}
      role="button"
      aria-label={`View Cart. Items in cart: ${cartItemsCount}`}
    />
  );
}
