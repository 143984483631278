import { useState, KeyboardEvent, FormEvent } from 'react';
import SearchBarWidget from 'sfx-app/src/searchBarWidget/SearchBarWidget';
import { config } from '../../config';
import { useConfig } from '../../common/hooks/config/useConfig';

export default function SearchBarWidgetWrapper() {
  const [searchKey, setSearchKey] = useState<string>('');
  const { configOverride } = useConfig();

  const onSubmit = (event: FormEvent<HTMLFormElement> | KeyboardEvent) => {
    event.preventDefault();
    window.open(`${config.searchUrl}${searchKey}`, '_self');
  };

  return (
    <SearchBarWidget
      value={searchKey}
      setValue={setSearchKey}
      placeholder={configOverride.searchBar.placeholder}
      ariaLabel={configOverride.searchBar.placeholder}
      onSubmit={onSubmit}
    />
  );
}
