import React from 'react';
import * as ReactDOM from 'react-dom/client';
import AppWrappers from './AppWrappers';
import WidgetPortalGun from './WidgetPortalGun';

/**
 * An auto-executing process run on load (on DOMContentLoaded being fired) that
 * sets up the library so that it populates our instacart elements with the
 * corresponding widget's React code.
 */

console.log('version---->', import.meta.env.VITE_VERSION);

export default function initialize() {
  const root = document.createElement('div');
  document.body.appendChild(root);

  ReactDOM.createRoot(root).render(
    <React.StrictMode>
      <AppWrappers>
        <WidgetPortalGun />
      </AppWrappers>
    </React.StrictMode>,
  );
}

// NOTE: mechanism from https://developer.mozilla.org/en-US/docs/Web/API/Document/DOMContentLoaded_event#checking_whether_loading_is_already_complete
if (document.readyState === 'loading') {
  // Loading hasn't finished yet
  document.addEventListener('DOMContentLoaded', initialize);
} else {
  // `DOMContentLoaded` has already fired
  initialize();
}
