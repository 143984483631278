import { type R2WCOptions } from '@r2wc/core';

export const domElementComponentMap = {
  'instacart-cart-button': {
    props: {
      size: 'string',
    },
    componentLoader: () => import('./src/cartButtonWrapper/CartButtonWrapper'),
  },
  'instacart-auth-widget': {
    props: {},
    componentLoader: () => import('./src/authWidgetWrapper/AuthWidgetWrapper'),
  },
  'instacart-collection': {
    props: {
      collectionId: 'string',
      hideViewAll: 'boolean',
      viewAllCustomLink: 'string',
    },
    componentLoader: () =>
      import('./src/productCollectionsWrapper/CollectionWrapper'),
  },
  'instacart-search-bar-widget': {
    props: {},
    componentLoader: () =>
      import('./src/searchBarWidgetWrapper/SearchBarWidgetWrapper'),
  },
} as const;

// Type checks

export type ComponentLoader<PropT extends object> = () => Promise<{
  default: React.ComponentType<PropT>;
}>;

type WidgetComponentTagEntry<PropT extends object> = {
  props: Record<keyof PropT, string> & R2WCOptions<PropT>['props'];
  componentLoader: ComponentLoader<PropT>;
};

// Check for all prop types declared for each imported component prop
// To fix errors, ensure all component props are defined in the domElementComponentMap object
domElementComponentMap satisfies {
  [tag in keyof typeof domElementComponentMap]: (typeof domElementComponentMap)[tag]['componentLoader'] extends ComponentLoader<
    infer PropT
  >
    ? WidgetComponentTagEntry<PropT>
    : never;
};
