import axios from 'axios';
import { config } from '../../config';
import { LocationData, ShopsData } from '../hooks/store/useStoreInitializer';

const { apiBaseUrl } = config;

export const getUserLocation = async () => {
  return await axios.get<LocationData>(
    `${apiBaseUrl}/idp/v1/users/self/location`,
    {
      withCredentials: true,
    },
  );
};

export const getStores = async (postalCode: string) => {
  return await axios.get<ShopsData>(`${apiBaseUrl}/idp/v1/shops`, {
    withCredentials: true,
    params: {
      postal_code: postalCode,
    },
  });
};
