import { createContext } from 'react';
import { Theme, config } from '../../../config';

type ConfigContextValue = {
  retailerName: string;
  apiBaseUrl: string;
  configOverride: Theme;
  /** TODO: this is so we can eventually fetch theme value at runtime from IPP rather than build time */
  setConfigOverride: (value: Theme) => void;
};

// NOTE: to enable HMR while editing config files, make sure you don't cause
// this file to update by using the actual theme value in this export
export const ConfigContext = createContext<ConfigContextValue>({
  retailerName: config.retailerName,
  apiBaseUrl: config.apiBaseUrl,
  configOverride:
    null as unknown as Theme /* This value must be set in the context, so this type error is safe to ignore */,
  setConfigOverride() {},
});
