import { css } from '@emotion/react';
import {
  AccountIcon,
  ButtonBase,
  useTheme,
  responsive,
  spacing,
} from '@instacart/ids-core';
import { AuthConfig } from 'widget-wrappers/config';

const useStyles = (overrides: AuthConfig['styles']) => {
  const theme = useTheme();
  return {
    button: css`
      background-color: ${overrides?.backgroundColor ||
      theme.colors.systemGrayscale00};
      font-size: ${overrides?.fontSize || '14px'};
      color: ${overrides?.textColor || theme.colors.brandPrimaryRegular};
      line-height: 20px;
      font-weight: 700;
      width: ${overrides?.width || '172px'};
      height: ${overrides?.height || '48px'};
      border: ${overrides?.border ??
      `${overrides?.borderWidth || '1px'} solid
        ${overrides?.borderColor || theme.colors.brandPrimaryRegular}`};
      border-radius: ${overrides?.borderRadius || `${theme.radius.r24}px`};
      font-family: inherit;
      padding: ${overrides?.padding || '10px'};
      ${responsive.down('c')} {
        font-size: ${overrides?.fontSize || '12px'};
        padding: ${overrides?.mobilePadding || '10px'};
      }
      ${overrides},
    `,
    content: css`
      display: flex;
      align-items: center;
      gap: ${spacing.s8}px;
      justify-content: center;
    `,
  };
};

export type AuthWidgetProps = {
  onPress: () => void;
  configOverrides?: AuthConfig;
};

export const AuthWidget = ({ onPress, configOverrides }: AuthWidgetProps) => {
  const styles = useStyles(configOverrides?.styles);

  return (
    <ButtonBase styles={styles} onClick={onPress}>
      {configOverrides?.showAvatarIcon ? (
        <AccountIcon
          aria-hidden
          color={configOverrides?.styles?.btnIconColor || 'brandPrimaryRegular'}
          size={configOverrides?.styles?.btnIconSize || 24}
        />
      ) : null}
      {configOverrides?.label}
    </ButtonBase>
  );
};

export default AuthWidget;
