import axios from 'axios';
import { config } from '../../config';

const { apiBaseUrl } = config;

export type ProductCollectionData = {
  name: string;
  code: string;
  owner: string;
  nested_collections?: ProductCollectionData[];
};

export type Collections = {
  collections: ProductCollectionData[];
};

type Details = {
  details_description?: string;
  ingredients?: string;
};

type Money = {
  amount_cents: number;
  currency: string;
};

type Price = {
  unit_price?: Money;
  estimated_each_price?: Money;
  reduction_factors?: unknown;
  type?: 'BASE' | 'FINAL';
};

type Pricing = {
  offer_label_string?: string;
  prices?: Price[];
  [key: string]: unknown; // other fields may be unaccounted for
};

type MeasuredQuantity = {
  measurement_unit:
    | 'COST_UNIT_UNSPECIFIED'
    | 'EACH'
    | 'LB'
    | 'KG'
    | 'OZ'
    | 'FL_OZ'
    | 'ML'
    | 'GRAM'
    | 'GALLON'
    | 'PINT'
    | 'QUART'
    | 'LITRE';
  quantity: string;
};

type QuantityAttributes = {
  quantity_type: 'EACH' | 'WEIGHT';
  variable_weight?: boolean;
  par_weight?: MeasuredQuantity;
  unit: 'EACH' | 'LB' | 'KG' | 'PACKAGE';
  increment: number;
};

type ShopLevelAttributes = {
  quantity_attributes: QuantityAttributes;
  availability?: 'IN_STOCK' | 'LOW_STOCK' | 'OUT_OF_STOCK';
  size?: string;
  pricing?: Pricing;
  details?: Details;
};

export type Product = {
  id: string;
  name: string;
  permalink_url: string;
  image_url: string;
  shop_id?: string;
  shop_level_attributes?: ShopLevelAttributes;
};

export type Products = {
  products: Product[];
};

export type ProductIds = {
  product_ids: string[];
};

export const getCollections = async (shopId: string, code: string) => {
  return await axios.get<ProductIds>(
    `${apiBaseUrl}/idp/v1/collections/${code}/product_ids?shop_id=${shopId}`,
    {
      withCredentials: true,
    },
  );
};

export const getProducts = async (
  shopId: string,
  productIds: ProductIds['product_ids'],
) => {
  const queryString = productIds.map((id) => `&product_ids[]=${id}`).join('');
  return await axios.get<Products>(
    `${apiBaseUrl}/idp/v1/products?shop_id=${shopId}${queryString}&expands[]=price&expands[]=details&expands[]=availability`,
    {
      withCredentials: true,
    },
  );
};
