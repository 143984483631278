import { useEffect, useState, useCallback } from 'react';
import { getStores, getUserLocation } from '../../services/store.service';

export type AddressData = {
  street_address: string;
  city?: string;
  state?: string;
  postal_code: string;
  country_code: 'US' | 'CA';
};

export type LocationData = {
  postal_code: string;
};

export type ShopContext = 'delivery' | 'pickup' | 'instore';

type ShopData = {
  name: string;
  id: string;
  retailer_key?: string;
  fulfillment_option: ShopContext;
  address: AddressData;
  phone_number: string;
};

export type ShopsData = {
  shops: Array<ShopData>;
};

const useStoreInitializer = () => {
  const [data, setData] = useState<ShopsData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    try {
      // First API call to get zip code/postal code for the user
      const location = await getUserLocation();

      // Second API call to get store using the location ID
      const listOfShops = await getStores(location.data.postal_code);
      // Set the data received from the second API call
      setData(listOfShops.data);
      setLoading(false);
    } catch (err) {
      let message = 'Store context initialization failed';
      if (err instanceof Error) {
        message = err.message;
      }
      setError(message);
      setLoading(false);
    }
  }, []); // Ensure the callback does not change

  useEffect(() => {
    void fetchData();
  }, [fetchData]); // Add fetchData to the dependency array

  return { data, loading, error };
};

export default useStoreInitializer;
