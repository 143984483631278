import { Dispatch, SetStateAction, useMemo } from 'react';
import { AddItemStepperBase } from '@instacart/ids-customers';
import {
  AddIcon,
  ListAddIcon,
  useTheme,
  responsive,
} from '@instacart/ids-core';
import { isMobile } from 'react-device-detect';
import { css } from '@emotion/react';
import { ShopContext } from 'widget-wrappers/common/hooks/store/useStoreInitializer';

const useStyles = () => {
  const theme = useTheme();
  return {
    buttonContainer: css`
      position: absolute;
      top: 10px;
      right: 5px;
    `,
    expandItemButton: {
      decrementButton: css`
        border-bottom-left-radius: 18px !important;
        ${responsive.down('c')} {
          border-bottom-left-radius: 16px !important;
        }
        background-color: ${theme.colors.brandPrimaryRegular};
        &:hover {
          background-color: ${theme.colors.brandPrimaryExtraDark};
        }
        &:disabled > svg {
          fill: ${theme.colors.systemGrayscale30};
        }
        &:disabled {
          background-color: ${theme.colors.brandPrimaryRegular};
          & > svg {
            fill: ${theme.colors.systemGrayscale30};
          }
        }
        & > svg {
          fill: ${theme.colors.systemGrayscale00};
        }
      `,
      incrementButton: css`
        border-bottom-right-radius: 18px !important;
        ${responsive.down('c')} {
          border-bottom-right-radius: 16px !important;
        }
        background-color: ${theme.colors.brandPrimaryRegular};
        &:hover {
          background-color: ${theme.colors.brandPrimaryExtraDark};
        }
        &:disabled {
          background-color: ${theme.colors.brandPrimaryRegular};
          & > svg {
            fill: ${theme.colors.systemGrayscale30};
          }
        }
        & > svg {
          fill: ${theme.colors.systemGrayscale00};
        }
      `,
      text: css`
        color: ${theme.colors.systemGrayscale00};
        font-size: 15px;
        font-weight: 600;
      `,
      wrapper: css`
        background-color: ${theme.colors.brandPrimaryRegular};
        border-radius: 20px;
      `,
    },
    addItemButton: {
      button: css`
        display: flex;
        align-items: center;
        background-color: ${theme.colors.brandPrimaryRegular};
        border-radius: ${theme.radius.r24}px;
        border: 2px solid ${theme.colors.brandPrimaryRegular};
        color: ${theme.colors.systemGrayscale00};
        padding: 6px 12px;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);
        font-size: 15px;
        font-weight: 600;

        ${responsive.down('c')} {
          padding: 6px;
        }

        &:hover {
          background-color: ${theme.colors.brandPrimaryExtraDark};
          border: 2px solid ${theme.colors.systemSuccessRegular};
        }

        > svg {
          fill: ${theme.colors.systemGrayscale00};
        }
      `,
      text: css`
        color: ${theme.colors.systemGrayscale00};
        font-size: 15px;
        font-weight: 600;
      `,
    },
  };
};

export type AddToCartWidgetProps = {
  incrementCount: number;
  setCartItemsCount: Dispatch<SetStateAction<number>>;
  description: string;
  itemCount: number;
  setItemCount: (count: number) => void;
  isHoveredCard?: boolean;
  shopContext?: ShopContext;
};

function AddToCartWidget({
  incrementCount = 1,
  setCartItemsCount,
  description,
  itemCount,
  setItemCount,
  shopContext,
  isHoveredCard,
}: AddToCartWidgetProps) {
  const styles = useStyles();

  const unit = '';
  const valueText = useMemo(
    () => (itemCount > 0 ? `${itemCount} ${unit}` : undefined),
    [itemCount],
  );
  const decrementType = useMemo(
    () => (itemCount > 1 ? ('subtract' as const) : ('trash' as const)),
    [itemCount],
  );

  const increaseItem = () => {
    setItemCount(itemCount + incrementCount);
    setCartItemsCount((cartItemCount) => cartItemCount + incrementCount);
  };

  const decrementItem = () => {
    if (itemCount !== 0) {
      setItemCount(itemCount - incrementCount);
      setCartItemsCount((cartItemCount) => cartItemCount - incrementCount);
    }
  };

  const isInStore = shopContext === 'instore';

  return (
    <div css={styles.buttonContainer}>
      {itemCount > 0 ? (
        <AddItemStepperBase
          styles={styles.expandItemButton}
          allowIncrement={true}
          allowDecrement={true}
          onIncrement={increaseItem}
          onDecrement={decrementItem}
          value={itemCount}
          valueText={valueText}
          ariaIncrement={`Increment quantity of ${description}`}
          ariaDecrement={
            itemCount > 1
              ? `Decrement quantity of ${description}`
              : `Remove ${description}`
          }
          ariaValue={`${itemCount}`}
          decrementType={decrementType}
        />
      ) : (
        <button
          css={styles.addItemButton.button}
          onClick={increaseItem}
          aria-label={`Add ${incrementCount} item ${description}`}
        >
          {isInStore ? <ListAddIcon size={24} /> : <AddIcon size={24} />}
          {!isMobile && (
            <>
              <span css={styles.addItemButton.text}>
                {isHoveredCard
                  ? isInStore
                    ? 'Add to list'
                    : 'Add to cart'
                  : 'Add'}
              </span>
            </>
          )}
        </button>
      )}
    </div>
  );
}

export default AddToCartWidget;
