import {
  LogOutIcon,
  GearIcon,
  OrderIcon,
  MarkerIcon,
  CardIcon,
  NotificationIcon,
  PromotionIcon,
} from '@instacart/ids-core';
import { Theme } from 'widget-wrappers/config';

const btfTheme: Theme = {
  styles: {
    colors: {
      brandPrimaryRegular: '#000000',
      brandLoyaltyRegular: '#C03633',
      brandPromotionalRegular: '#C03633',
    },
  },
  authWidget: {
    label: 'Sign In / Register',
    styles: {
      border: 'solid 0px #101820',
      borderRadius: '0px',
      backgroundColor: '#D9D9D9',
    },
  },
  profileMenu: {
    profileOptions: [
      {
        icon: OrderIcon,
        color: 'brandSecondaryDark',
        label: 'Your orders',
        link: '/store/account/orders',
      },
      {
        icon: GearIcon,
        color: 'brandSecondaryDark',
        label: 'Account settings',
        link: '/store/account/profile',
      },
      {
        icon: MarkerIcon,
        color: 'brandSecondaryDark',
        label: 'Addresses',
        link: '/store/account/addresses',
      },
      {
        icon: CardIcon,
        color: 'brandSecondaryDark',
        label: 'Payment methods',
        link: '/store/account/payment',
      },
      {
        icon: PromotionIcon,
        color: 'brandSecondaryDark',
        label: 'Credits, promos and gift cards',
        link: '/store/account/manage_promos',
      },
      {
        icon: NotificationIcon,
        color: 'brandSecondaryDark',
        label: 'Notifications settings',
        link: '/store/account/notifications',
      },
      {
        icon: LogOutIcon,
        color: 'brandSecondaryDark',
        label: 'Log out',
        link: '/accounts/logout',
        type: 'logout',
      },
    ],
    showProfileIcon: true,
    showUserName: true,
    profileIconColor: undefined,
    showChevronIcon: false,
    styles: {
      nameLabelStyle: {
        color: '#2A2A2A',
        fontSize: '14px',
        fontWeight: '700',
      },
      btnIconColor: 'brandSecondaryDark',
      btnIconSize: 24,
      cornerRadius: 0,
      mobilePosition: 'left',
    },
  },
  searchBar: {
    placeholder: 'Search Products...',
  },
  cartWidget: {},
  productCollections: {},
};

export default btfTheme;
