import axios from 'axios';
import { config } from '../../config';
import { UserData } from '../hooks/auth/AuthContext';

const { apiBaseUrl } = config;

export const getUser = async () => {
  return await axios.get<UserData>(`${apiBaseUrl}/idp/v1/users/self`, {
    withCredentials: true,
  });
};
