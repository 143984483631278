import { Dispatch, SetStateAction, useMemo, useRef, useState } from 'react';
import { useTheme, spacing } from '@instacart/ids-core';
import AddToCartWidget from '../addToCart/AddToCartWidget';
import { css } from '@emotion/react';
import { useHover } from 'usehooks-ts';
import { Product } from 'widget-wrappers/common/services/collections.service';
import { CartItem } from 'widget-wrappers/common/services/cart.service';
import { useLayoutEffect } from 'react';
import { ShopContext } from 'widget-wrappers/common/hooks/store/useStoreInitializer';

export const DEFAULT_PRODUCT_WIDGET_WIDTH = 200;

type UseStyleOptions = {
  cardWidth?: number;
  noPadding?: boolean;
};

export type ProductWidgetProps = {
  heightResizeObserver?: ResizeObserver;
  item: Product;
  setCartItemsCount: Dispatch<SetStateAction<number>>;
  putCartItems: (shopId: string, cartItem: CartItem) => Promise<void>;
  quantity: number;
  shopContext?: ShopContext;
} & UseStyleOptions;

const useStyles = ({ cardWidth, noPadding }: UseStyleOptions) => {
  const theme = useTheme();
  return {
    product: css`
      position: relative;
      width: ${cardWidth ?? DEFAULT_PRODUCT_WIDGET_WIDTH}px;
      justify-content: center;
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      padding: ${noPadding ? 0 : spacing.s12}px;
      border-radius: ${theme.radius.r12}px;
    `,
    productImage: css`
      width: 100%;
    `,
    productPrice: css`
      display: flex;
      align-items: flex-end;
    `,
    productSalePrice: css`
      display: flex;
      font-weight: 500;
      font-size: 11px;
      line-height: 11px;
      color: ${theme.colors.systemGrayscale80};
    `,
    productSaleYellowBox: css`
      background-color: ${theme.colors.brandMaxLight};
      padding: ${spacing.s4}px;
    `,
    priceLabel: css`
      font-size: 21px;
      font-weight: 700;
      line-height: 17px;
      padding: 0px 1px;
    `,
    productAltPrice: css`
      font-weight: 400;
      font-size: 11px;
      line-height: 14px;
      margin-left: 2px;
      text-decoration-line: line-through;
    `,
    productDescription: css`
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      color: ${theme.colors.systemGrayscale80};
      margin: 4px 0px;
    `,
    productLabel: css`
      font-size: 10px;
      font-weight: 700;
    `,
    productUnit: css`
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: ${theme.colors.systemGrayscale60};
    `,
    offerLabel: css`
      background-color: ${theme.colors.brandPromotionalRegular};
      padding: ${spacing.s4}px;
      border-radius: ${spacing.s4}px;
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      color: ${theme.colors.systemGrayscale00};
      margin-top: 7px;
    `,
  };
};

export function ProductWidget({
  item,
  setCartItemsCount,
  putCartItems,
  quantity,
  cardWidth,
  shopContext,
  noPadding,
  heightResizeObserver,
}: ProductWidgetProps) {
  const cardContainerRef = useRef<HTMLDivElement>(null);
  const isHover = useHover(cardContainerRef);
  const styles = useStyles({ cardWidth, noPadding });

  const [itemQuantity, setItemQuantity] = useState(quantity);

  const finalPrice = useMemo(() => {
    const resultPrice = item?.shop_level_attributes?.pricing?.prices?.find(
      (price) => price.type === 'FINAL',
    );
    if (resultPrice) {
      const price = Number(resultPrice?.unit_price?.amount_cents) / 100;
      return price.toString().split('.');
    }
    return null;
  }, [item]);

  const basePrice = useMemo(() => {
    const resultPrice = item?.shop_level_attributes?.pricing?.prices?.find(
      (price) => price.type === 'BASE',
    );
    if (resultPrice) {
      const price = Number(resultPrice?.unit_price?.amount_cents) / 100;
      return price.toString();
    }
    return null;
  }, [item]);

  const unitSize = useMemo(() => {
    return item.shop_level_attributes?.size;
  }, [item]);

  const parWeight = useMemo(() => {
    if (item?.shop_level_attributes?.quantity_attributes?.variable_weight) {
      const weight =
        item?.shop_level_attributes?.quantity_attributes?.par_weight;
      return `About ${weight?.quantity}${weight?.measurement_unit}`;
    }
    return null;
  }, [item]);

  const offerLabel = useMemo(() => {
    return item.shop_level_attributes?.pricing?.offer_label_string;
  }, [item]);

  const handleCartItem = (quantity: number) => {
    const cartItem: CartItem = {
      product_id: item.id,
      quantity,
      quantity_type:
        item.shop_level_attributes?.quantity_attributes?.quantity_type || '',
    };
    if (item?.shop_id) {
      void putCartItems(item?.shop_id, cartItem);
    }
  };

  const handleItemCount = (quantity: number) => {
    setItemQuantity(quantity);
    handleCartItem(quantity);
  };

  useLayoutEffect(() => {
    // Notify parent container of the height this component needs - for
    // react-window since it uses absolute positioning to achieve its goals,
    // which means that the container's height needs to be fixed
    if (!heightResizeObserver) return;
    const containerDiv = cardContainerRef.current;
    if (!containerDiv)
      throw new Error('Expected cardContainerRef to be defined if mounted');
    heightResizeObserver.observe(cardContainerRef.current);
    return () => {
      heightResizeObserver.unobserve(containerDiv);
    };
  }, [heightResizeObserver]);

  return (
    <div css={styles.product} ref={cardContainerRef}>
      <div>
        <a
          href={item.permalink_url}
          target="_blank"
          data-testid="product-permalink-url"
          rel="noreferrer"
        >
          <img
            src={item.image_url}
            css={styles.productImage}
            alt="product-img"
          />
        </a>
        <AddToCartWidget
          setCartItemsCount={setCartItemsCount}
          incrementCount={
            item.shop_level_attributes?.quantity_attributes?.increment || 1
          }
          description={item.name}
          itemCount={itemQuantity}
          setItemCount={handleItemCount}
          isHoveredCard={isHover}
          shopContext={shopContext}
        />
      </div>
      <div css={styles.productPrice}>
        {finalPrice && (
          <div
            css={[
              styles.productSalePrice,
              basePrice ? styles.productSaleYellowBox : '',
            ]}
          >
            $<span css={styles.priceLabel}>{finalPrice[0]}</span>
            {finalPrice[1]}
          </div>
        )}
        {basePrice && <div css={styles.productAltPrice}>${basePrice}</div>}
      </div>
      {offerLabel && <div css={styles.offerLabel}>{offerLabel}</div>}
      <div css={styles.productDescription}>{item.name}</div>
      <div css={styles.productUnit}>{parWeight || unitSize}</div>
    </div>
  );
}

export default ProductWidget;
