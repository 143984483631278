import { useEffect, useState, useCallback } from 'react';

type Initializer = () => Promise<void>;

const useAppInitializer = (callback: Initializer): boolean => {
  const [isInitialized, setIsInitialized] = useState(false);

  // Callback is memoized to prevent unnecessary re-rendering
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedCallback = useCallback(callback, []);

  useEffect(() => {
    const asyncInitialisation = async () => {
      try {
        await memoizedCallback();
        setIsInitialized(true);
      } catch (error) {
        // error handling here
        console.error('Initialization error: ', error);
      }
    };

    void asyncInitialisation();
  }, [memoizedCallback]); // Dependency is updated to reflect memoization

  return isInitialized;
};

export default useAppInitializer;
