import { ProfileMenuConfig } from 'widget-wrappers/config';
import { UserData } from 'widget-wrappers/common/hooks/auth/AuthContext';
import { forwardRef, useMemo } from 'react';
import { css } from '@emotion/react';
import {
  AccountIcon,
  ChevronRightIcon,
  responsive,
  spacing,
  useTheme,
} from '@instacart/ids-core';

export type AvatarContainerProps = {
  configOverrides: ProfileMenuConfig;
  user: UserData | null;
};

const useStyles = (
  overrides: ProfileMenuConfig['styles'],
  showUserName = false,
) => {
  const theme = useTheme();
  return {
    avatarContainer: css`
      position: relative;
      width: ${showUserName
        ? 'fit-content'
        : overrides?.btnIconSize + 'px' || '28px'};
      height: ${showUserName
        ? 'fit-content'
        : overrides?.btnIconSize + 'px' || '28px'};
      align-items: center;
      padding: 10px;
      cursor: pointer;
      display: ${showUserName ? 'flex' : 'block'};
      ${responsive.down('c')} {
        padding: ${overrides?.mobilePadding || '10px'};
      }
    `,
    nameLabel: css`
      font-size: ${overrides?.nameLabelStyle?.fontSize || '14px'};
      font-weight: ${overrides?.nameLabelStyle?.fontWeight || 700};
      padding-left: ${spacing.s8}px;
      line-height: 20px;
      color: ${overrides?.nameLabelStyle?.color ||
      theme.colors.systemGrayscale00};
      ${responsive.down('c')} {
        line-height: 15px;
      }
    `,
    chevronIcon: css`
      display: none;
      margin-left: ${spacing.s32}px;
      ${responsive.down('c')} {
        display: block;
      }
    `,
  };
};

export const AvatarContainer = forwardRef<HTMLDivElement, AvatarContainerProps>(
  ({ configOverrides, user }, ref) => {
    const styles = useStyles(
      configOverrides.styles,
      configOverrides.showUserName,
    );
    const username = useMemo(() => {
      if (user && configOverrides.showUserName) {
        const capitalizeFirstLetter = (str: string) =>
          str.charAt(0).toUpperCase() + str.slice(1);
        const first = capitalizeFirstLetter(user.first_name || '');
        const last = capitalizeFirstLetter(user.last_name?.charAt(0) || '');
        return `${first} ${last}.`;
      }
      return null;
    }, [configOverrides.showUserName, user]);

    return (
      <div
        data-testid="avatar-container"
        css={styles.avatarContainer}
        tabIndex={0}
        ref={ref}
      >
        <AccountIcon
          color={configOverrides.styles?.btnIconColor || 'brandPrimaryRegular'}
          size={configOverrides.styles?.btnIconSize || 24}
        />
        {username ? <span css={styles.nameLabel}>{username}</span> : null}
        {configOverrides.showChevronIcon && (
          <ChevronRightIcon
            size={configOverrides.styles?.chevronIconStyle?.size || 18}
            css={styles.chevronIcon}
            color={
              configOverrides.styles?.chevronIconStyle?.color ||
              'systemGrayscale60'
            }
          />
        )}
      </div>
    );
  },
);
AvatarContainer.displayName = 'AvatarContainer';
