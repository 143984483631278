import { useMemo } from 'react';
import { LoadingLockupGridItem } from '@instacart/ids-core';
import { useTheme } from '@instacart/ids-core';
import {
  ProductWidget,
  ProductWidgetProps,
} from 'sfx-app/src/productWidget/ProductWidget';
import { CartItems } from '../../common/services/cart.service';

type Props = {
  cartCollectionItems?: CartItems;
} & Omit<ProductWidgetProps, 'quantity'>;

const useStyles = (width?: number) => {
  const theme = useTheme();
  return {
    loadingBar: {
      container: {
        width: width,
        backgroundColor: theme.colors.systemGrayscale00,
        borderRadius: theme.radius.r12,
        paddingBottom: theme.radius.r24,
      },
    },
  };
};

export default function ProductCard({
  item,
  cartCollectionItems,
  setCartItemsCount,
  putCartItems,
  cardWidth,
  heightResizeObserver,
  shopContext,
}: Props) {
  const styles = useStyles(cardWidth);

  const qty = useMemo(
    () =>
      cartCollectionItems?.items.find((e) => e.product_id === item?.id)
        ?.quantity || 0,
    [cartCollectionItems?.items, item?.id],
  );

  if (!item) {
    return <LoadingLockupGridItem styles={styles.loadingBar} />;
  }

  return (
    <ProductWidget
      heightResizeObserver={heightResizeObserver}
      item={item}
      setCartItemsCount={setCartItemsCount}
      putCartItems={putCartItems}
      quantity={qty}
      cardWidth={cardWidth}
      shopContext={shopContext}
      noPadding
    />
  );
}
