import { ChevronLeftIcon, ChevronRightIcon } from '@instacart/ids-core';
import { css } from '@emotion/react';
import { useTheme } from '@instacart/ids-core';
import { useConfig } from '../../common/hooks/config/useConfig';
import ViewAllCollection from './ViewAllCollection';

const useStyles = () => {
  const theme = useTheme();
  return {
    buttonStyle: css`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      outline: none;
      background-color: ${theme.colors.systemGrayscale20};
      border: none;
      cursor: pointer;
      border-radius: 100%;
      margin: 0 5px;

      &:disabled {
        opacity: 0.5;
      }
    `,
  };
};

const useOverrideConfig = () => {
  const {
    configOverride: { productCollections },
  } = useConfig();

  return {
    iconColor:
      productCollections.listWrapper?.chevronIconStyle?.color ||
      'systemGrayscale70',
    iconSize: productCollections.listWrapper?.chevronIconStyle?.size || 20,
  };
};

type PagerProps = {
  viewAllLink: string | null;
  currentPage: number;
  disabled: boolean;
  totalCount: number;
  totalPage: number;
  onNext?: () => void;
  onPrev?: () => void;
};

export const CollectionPager = ({
  viewAllLink,
  currentPage,
  disabled,
  totalCount,
  totalPage,
  onNext,
  onPrev,
}: PagerProps) => {
  const styles = useStyles();
  const overrideThemeConfig = useOverrideConfig();

  return (
    <>
      {viewAllLink ? (
        <ViewAllCollection viewAllLink={viewAllLink} totalCount={totalCount} />
      ) : null}
      <button
        css={styles.buttonStyle}
        onClick={onPrev}
        disabled={currentPage <= 0 || disabled}
      >
        <ChevronLeftIcon
          color={overrideThemeConfig.iconColor}
          size={overrideThemeConfig.iconSize}
        />
      </button>
      <button
        css={styles.buttonStyle}
        onClick={onNext}
        disabled={currentPage >= totalPage - 1 || disabled}
      >
        <ChevronRightIcon
          color={overrideThemeConfig.iconColor}
          size={overrideThemeConfig.iconSize}
        />
      </button>
    </>
  );
};
