// Config file generated by build process per retailer
import { ComponentType } from 'react';
import {
  Color,
  DeprecatedColors,
  SVGIconProps,
  ThemeOverrides,
} from '@instacart/ids-core';

export interface Config {
  apiBaseUrl: string;
  retailerName: string;
  searchUrl: string;
  retailerSlug?: string;
}

export type AuthConfigStyles = {
  backgroundColor: string;
  borderColor: string;
  textColor: string;
  fontSize: string;
  height: string;
  width: string;
  borderWidth: string;
  borderRadius: string;
  border: string;
  btnIconColor: Color | keyof DeprecatedColors | 'tertiaryRegular' | undefined;
  btnIconSize: number;
  padding?: string;
  mobilePadding?: string;
};

export type AuthConfig = {
  label: string;
  showAvatarIcon?: boolean;
  styles?: Partial<AuthConfigStyles>;
};

export interface IProfileOptions {
  icon: ComponentType<Omit<SVGIconProps, 'component'>>;
  color: string;
  label: string;
  link: string;
  type?: string;
}

export type ProfileMenuConfig = {
  profileOptions: IProfileOptions[];
  styles?: Partial<{
    backgroundColor: string;
    cornerRadius?: number;
    btnIconColor:
      | Color
      | keyof DeprecatedColors
      | 'tertiaryRegular'
      | undefined;
    btnIconSize: number;
    profileItemFontSize: string;
    nameLabelStyle?: {
      fontSize: string;
      color: string;
      fontWeight: string;
    };
    chevronIconStyle?: {
      size: number;
      color: Color | keyof DeprecatedColors | 'tertiaryRegular' | undefined;
    };
    dropDownMenuStyles?: {
      right?: string;
      left?: string;
    };
    mobilePosition: 'left' | 'center';
    mobilePadding?: string;
  }>;
  showProfileIcon: boolean;
  showUserName: boolean;
  showChevronIcon: boolean;
  clickUrlOnMobile?: string;
  profileIconColor:
    | Color
    | keyof DeprecatedColors
    | 'tertiaryRegular'
    | undefined;
};

export type searchBarConfig = {
  placeholder: string;
};

export type CartWidgetConfig = {
  cartItemsCount?: number;
  styles?: Partial<{
    backgroundColor: string;
    borderColor: string;
    borderWidth: string;
    borderRadius: string;
    textColor: string;
    fontSize: string;
    iconColor: string;
  }>;
};

export type ProductCollectionsConfig = {
  listWrapper?: {
    chevronIconStyle?: {
      size?: number;
      color?: Color | keyof DeprecatedColors | 'tertiaryRegular' | undefined;
    };
  };
};

export interface Theme {
  styles: ThemeOverrides;
  mobileBreakpoint?: number;
  authWidget: AuthConfig;
  profileMenu: ProfileMenuConfig;
  searchBar: searchBarConfig;
  cartWidget: CartWidgetConfig;
  productCollections: ProductCollectionsConfig;
}

export const config: Config = {
  /* localhost server requests starting with '/idp' are proxied on to the real VITE_SHOP_HOST_URL value */
  apiBaseUrl:
    import.meta.env.DEV && window.location.hostname.includes('localhost')
      ? ''
      : import.meta.env.VITE_SHOP_HOST_URL,
  retailerName: import.meta.env.VITE_RETAILER_NAME,
  searchUrl: import.meta.env.VITE_SEARCH_URL,
  retailerSlug:
    import.meta.env.VITE_RETAILER_SLUG || import.meta.env.VITE_RETAILER_NAME,
};
