import {
  useEffect,
  useCallback,
  useState,
  useLayoutEffect,
  RefObject,
} from 'react';
import { spacing } from '@instacart/ids-core';
import { css } from '@emotion/react';
import { useRecoilState } from 'recoil';
import { useResizeObserver } from 'usehooks-ts';
import { DEFAULT_PRODUCT_WIDGET_WIDTH } from 'sfx-app/src/productWidget/ProductWidget';
import { cartItemsCountAtom, cartIdAtom } from '../../stores/cart-items';
import {
  Product,
  getCollections,
  getProducts,
} from '../../common/services/collections.service';
import useCartsPersonal from '../../common/hooks/carts/useCartsPersonal';
import {
  CartItem,
  CartItems,
  updateCartItems,
  getCartItems,
} from '../../common/services/cart.service';
import useStoreInitializer from '../../common/hooks/store/useStoreInitializer';
import { useIsMobile } from '../../common/hooks/useIsMobile';

export const ITEM_GAP = spacing.s16;

export const useStyles = () => {
  const isMobile = useIsMobile();
  return {
    wrapper: css`
      position: relative;
      min-width: 0;
    `,
    buttonBar: css`
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: ${spacing.s24}px;
    `,
    list: css`
      list-style: none;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: ${isMobile ? 'auto' : 'hidden'};
      touch-action: pan-x;
      scrollbar-width: none;
      padding-left: 0;
      padding-right: 0;
      padding-top: ${spacing.s8}px;
      margin-top: 0;
      margin-bottom: 0;
      gap: ${ITEM_GAP}px;

      > li {
        flex-shrink: 0;
      }
    `,
    noScrollbar: css`
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */

      ::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    `,
  };
};

export const useShopData = () => {
  const { data } = useStoreInitializer();

  return data?.shops[0];
};

export const useProductIdList = (collectionid: string, shopId?: string) => {
  const [data, setData] = useState<string[]>([]);

  useEffect(() => {
    void (async () => {
      if (shopId) {
        const response = await getCollections(shopId, collectionid);
        setData(response.data?.product_ids ?? []);
      }
    })();
  }, [collectionid, shopId]);

  return data;
};

export const useCart = () => {
  const cartsPersonalData = useCartsPersonal();
  const [cartId, setCardId] = useRecoilState<string>(cartIdAtom);
  const [cartCollectionItems, setcartCollectionItems] = useState<CartItems>();
  const [cartItemsCount, setCartItemsCount] =
    useRecoilState(cartItemsCountAtom);

  const putCartItems = useCallback(
    async (shopId: string, cartItems: CartItem) => {
      await updateCartItems(cartId, shopId, cartItems);
    },
    [cartId],
  );

  useEffect(() => {
    if (
      cartsPersonalData &&
      cartsPersonalData.data &&
      cartsPersonalData.data.carts
    ) {
      const id = cartsPersonalData.data.carts[0].id;
      void (async () => {
        const cartItems = await getCartItems(id);
        setCardId(id);
        setcartCollectionItems(cartItems.data);
      })();
    }
  }, [cartsPersonalData, setCardId]);

  return {
    cartId,
    cartCollectionItems,
    cartItemsCount,
    putCartItems,
    setCartItemsCount,
  };
};

type UseTileWidthOptions<T extends HTMLElement = HTMLElement> = {
  ref: RefObject<T>;
  productIds: string[];
  gap?: number;
};
export const useTileWidth = <T extends HTMLElement = HTMLElement>({
  ref,
  productIds,
  gap = 0,
}: UseTileWidthOptions<T>) => {
  const { width = 0 } = useResizeObserver({ ref, box: 'border-box' });
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [itemWidth, setItemWidth] = useState(DEFAULT_PRODUCT_WIDGET_WIDTH);

  useLayoutEffect(() => {
    if (productIds.length > 0 && width > 0) {
      const initialCount = Math.round(
        (width + gap) / DEFAULT_PRODUCT_WIDGET_WIDTH,
      );
      setItemWidth(Math.ceil((width + gap) / initialCount - gap));
      setItemsPerPage(initialCount);
    }
  }, [gap, productIds.length, width]);
  return { itemsPerPage, itemWidth };
};

export const useCachedProducts = (shopId?: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProductsMap] = useState<Record<string, Product>>({});

  const getProductInfo = useCallback(
    async (ids: string[]) => {
      if (ids.length > 0 && shopId) {
        try {
          setLoading(true);
          const response = await getProducts(shopId, ids);
          const { products: data } = response.data;
          setProductsMap((prev) => {
            data.forEach((p) => {
              prev[p.id] = p;
            });
            return { ...prev };
          });
        } catch (error) {
          console.error('Failed to fetch product info:', error);
        } finally {
          setLoading(false);
        }
      }
    },
    [shopId],
  );

  return { loading, getProductInfo, products };
};

export const useProductsPagination = (
  productIds: string[],
  itemsPerPage: number,
  shopId?: string,
) => {
  const [pages, setPages] = useState<string[][]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const { loading, getProductInfo, products } = useCachedProducts(shopId);

  useEffect(() => {
    if (productIds.length > 0 && itemsPerPage > 0) {
      const pages = Array.from(
        { length: Math.ceil(productIds.length / itemsPerPage) },
        (_, i) =>
          productIds.slice(i * itemsPerPage, i * itemsPerPage + itemsPerPage),
      );

      setPages(pages);
    }
  }, [getProductInfo, itemsPerPage, productIds]);

  useEffect(() => {
    if (pages[currentPage]) {
      void getProductInfo(pages[currentPage].filter((id) => !products[id]));
    }
  }, [currentPage, getProductInfo, pages, products]);

  return {
    currentPage,
    loading,
    products,
    setCurrentPage,
    totalPage: pages.length,
  };
};
