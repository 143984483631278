import { useTransitionIntoView } from '../../common/hooks/useTransitionIntoView';
import {
  useCart,
  useStyles,
  ITEM_GAP,
  useProductsPagination,
  useTileWidth,
} from './custom-hooks';
import { CollectionPager } from './CollectionPager';
import ProductCard from './ProductCard';
import { useRef } from 'react';
import { ShopContext } from '../../common/hooks/store/useStoreInitializer';

type Props = {
  productIds: string[];
  viewAllLink: string | null;
  shopId?: string;
  shopContext?: ShopContext;
};

export default function PaginatedList({
  productIds,
  viewAllLink,
  shopId,
  shopContext,
}: Props) {
  const styles = useStyles();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { itemWidth, itemsPerPage } = useTileWidth({
    ref: containerRef,
    productIds,
    gap: ITEM_GAP,
  });
  const { cartCollectionItems, putCartItems, setCartItemsCount } = useCart();

  const { currentPage, setCurrentPage, totalPage, loading, products } =
    useProductsPagination(productIds, itemsPerPage, shopId);

  const {
    scrollContainerRef,
    offsetForTransition,
    getTileProps,
    transitionIntoView,
  } = useTransitionIntoView(ITEM_GAP);

  const disableScroll = loading || offsetForTransition !== null;

  const scrollNext = () => {
    if (currentPage < totalPage - 1 && !disableScroll) {
      const nextPage = currentPage + 1;
      transitionIntoView(nextPage * itemsPerPage);
      setCurrentPage(nextPage);
    }
  };

  const scrollPrevious = () => {
    if (currentPage > 0 && !disableScroll) {
      const prevPage = currentPage - 1;
      transitionIntoView(prevPage * itemsPerPage);
      setCurrentPage(prevPage);
    }
  };

  return (
    <div css={styles.wrapper} ref={containerRef}>
      {Object.keys(products).length > 0 && totalPage > 1 ? (
        <div css={styles.buttonBar}>
          <CollectionPager
            currentPage={currentPage}
            disabled={disableScroll}
            totalCount={productIds.length}
            totalPage={totalPage}
            onNext={scrollNext}
            onPrev={scrollPrevious}
            viewAllLink={viewAllLink}
          />
        </div>
      ) : null}
      <ul ref={scrollContainerRef} css={styles.list}>
        {productIds.map((productId, i) => (
          <li {...getTileProps(i)} key={productId}>
            <ProductCard
              cardWidth={itemWidth}
              item={products[productId]}
              cartCollectionItems={cartCollectionItems}
              setCartItemsCount={setCartItemsCount}
              putCartItems={putCartItems}
              shopContext={shopContext}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
