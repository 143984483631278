import { KeyboardEvent, FormEvent } from 'react';
import {
  Theme,
  useTheme,
  SearchInputForm,
  SearchInput,
  SearchInputSubmitButton,
  responsive,
} from '@instacart/ids-core';
import { css } from '@emotion/react';

export type SearchBarWidgetProps = {
  value: string;
  setValue: (value: string) => void;
  placeholder: string;
  ariaLabel: string;
  onSubmit: (event: FormEvent<HTMLFormElement> | KeyboardEvent) => void;
};

const useStyles = (theme: Theme) => ({
  searchBar: css`
    width: 100%;
    height: 56px;
    border-radius: ${theme.radius.r8}px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) inset;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    box-sizing: border-box;
    color: ${theme.colors.brandSecondaryDark};
    &:focus {
      outline: none;
      border: 2px solid ${theme.colors.brandSecondaryDark};
    }
    ${responsive.down('c')} {
      height: 40px;
    }
  `,
});

export default function SearchBarWidget({
  value,
  setValue,
  placeholder,
  ariaLabel,
  onSubmit,
}: SearchBarWidgetProps) {
  const theme = useTheme();
  const styles = useStyles(theme);

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSubmit(event);
    }
  };

  return (
    <SearchInputForm onSubmit={(event) => onSubmit(event)}>
      <SearchInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => handleKeyPress(e)}
        placeholder={placeholder}
        role="search"
        aria-label={ariaLabel}
        aria-expanded={false}
        css={styles.searchBar}
      />
      <SearchInputSubmitButton />
    </SearchInputForm>
  );
}
