import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { cartItemsCountAtom, cartIdAtom } from '../../stores/cart-items';
import { config } from '../../config';
import { useConfig } from '../../common/hooks/config/useConfig';
import CartWidget from 'sfx-app/src/cartWidget/CartWidget';
import useCartsPersonal from '../../common/hooks/carts/useCartsPersonal';

export default function CartButtonWrapper() {
  const { configOverride } = useConfig();

  const [cartItemsCount, setCartItemsCount] =
    useRecoilState<number>(cartItemsCountAtom);
  const [, setCardId] = useRecoilState<string>(cartIdAtom);
  const cartsPersonalData = useCartsPersonal();

  useEffect(() => {
    if (
      cartsPersonalData &&
      cartsPersonalData.data &&
      cartsPersonalData.data.carts
    ) {
      const cart = cartsPersonalData.data.carts[0];
      setCartItemsCount(cart.item_count);
      setCardId(cart.id);
    }
  }, [cartsPersonalData, setCardId, setCartItemsCount]);

  const openCart = () => {
    window.open(`${config.apiBaseUrl}?cart_open`, '_self');
  };

  return (
    <CartWidget
      cartItemsCount={cartItemsCount}
      openCart={openCart}
      configOverrides={configOverride.cartWidget}
    />
  );
}
