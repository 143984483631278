import { createContext } from 'react';

export type UserData = {
  user_id: string;
  first_name: string;
  last_name: string;
  phone_number?: string;
  guest: boolean;
  metadata?: Record<string, unknown>;
};

type AuthContextValue = {
  authState: boolean;
  setAuthState: (value: boolean) => void;
  user: UserData | null;
  setUser: (value: UserData) => void;
};

export const AuthContext = createContext<AuthContextValue>({
  authState: false,
  setAuthState: () => {},
  user: null,
  setUser: () => {},
});
