import { RecoilRoot } from 'recoil';
import IdsThemeOverridesProvider from './IdsThemeOverridesProvider';
import ConfigProvider from '../common/hooks/config/ConfigProvider';
import AuthProvider from '../common/hooks/auth/AuthProvider';
import useAppInitializer from '../common/hooks/useAppInitializer';
import { init } from '../common/services/init.service';

function AppWrappers({ children }: { children: React.ReactNode }) {
  const initialize = async () => {
    // Fetch backend auth status here and set the cookie for Connect
    try {
      await init();
    } catch (error) {
      if (error instanceof Error) {
        console.error(`Failed to authorize the application: ${error.message}`);
      } else {
        console.error(`Failed to authorize the application: ${String(error)}`);
      }
    }
  };
  // TODO: Future proofing design show Loading components while initializing
  void useAppInitializer(initialize);

  return (
    <RecoilRoot>
      <ConfigProvider>
        <AuthProvider>
          <IdsThemeOverridesProvider>{children}</IdsThemeOverridesProvider>
        </AuthProvider>
      </ConfigProvider>
    </RecoilRoot>
  );
}

export default AppWrappers;
